import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaInstagram, FaFacebook } from 'react-icons/fa';

import logoImage from './assets/logo.webp';
import backgroundImage from './assets/backgroundimage.webp';
import aboutUsImage from './assets/abiutus.webp';
import whyuspic from './assets/whyuspic.webp';

const navigation = [
  { name: 'Page d\'Accueil', href: '#home', current: true },
  { name: 'À Propos de Nous', href: '#about', current: false },
  { name: 'Nos Services', href: '#services', current: false },
  { name: 'Pourquoi Nous Choisir', href: '#nous', current: false },
  { name: 'Contact', href: '#contact', current: false },
  
];


function App() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('#home'); // Track the active link
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
const [activeTab, setActiveTab] = useState('histoire');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLinkClick = (href) => {
    setActiveLink(href);
    // Close the mobile menu if open
    setIsMobileMenuOpen(false);
  };

  return (
    <div className="App">
      <nav className=" sticky top-0 z-50">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-20">
            <div className="flex items-center">
              <div className="flex-shrink-0">
              <img className="h-16 w-auto" src={logoImage} alt=" Company Logo" />
              </div>
            </div>
            <div className="hidden md:flex items-center space-x-4">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={`text-[#1F2937] hover:bg-[#2966A2] hover:text-white px-3 py-2 rounded-md text-sm font-medium ${
                    item.href === activeLink ? 'bg-[#26A4DB] text-white' : ''
                  }`}
                  onClick={() => handleLinkClick(item.href)}
                >
                  {item.name}
                </a>
              ))}
            </div>
            <div className="md:hidden">
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="inline-flex items-center justify-center p-2 text-[#1F2937] hover:bg-[#2966A2] hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              >
                {isMobileMenuOpen ? (
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16m-7 6h7" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        {isMobileMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={`text-[#1F2937] hover:bg-[#2966A2] hover:text-white block px-3 py-2 rounded-md text-base font-medium ${
                    item.href === activeLink ? 'bg-[#26A4DB] text-white' : ''
                  }`}
                  onClick={() => handleLinkClick(item.href)}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>
        )}
      </nav>
      <hr />
{/* HOME */}
      <section id='home'
      className="bg-cover bg-center h-screen flex items-center relative"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="absolute inset-0 bg-black opacity-10"></div>
      <div className=" z-10 lg:w-1/2 px-6 ml-16 ">
        <div className='mb-8'>
             <h1 className="text-4xl lg:text-6xl font-bold  gradient-text">
        Confiez votre déménagement à Déménagement Ultra Pro
        </h1>
        <p className="font-normal ">
  Bienvenue sur Déménagement Ultra Pro - Votre Partenaire de Confiance pour les Déménagements et Transferts Industriels.
</p   >

        </div>
  
        <div className="">
          <div className=" gap-4 items-start lg:items-center space-y-4 lg:space-x-4 lg:space-y-0">
            <button className="hover:bg-[#2966A2] bg-[#26A4DB] text-white font-normal py-2 px-4 rounded" href='#services'>
             <a href='#services'>Nos Services</a> 
            </button>
           
          
          </div>
        </div>
      </div>
    </section>

{/* ABOUT US */}
<div id='about'>

  <h2 className="text-xl lg:text-2xl font-semibold text-[#2966A2] mb-6 text-center mt-6">À Propos de Nous</h2>


<section className="flex flex-col lg:flex-row items-center lg:items-start justify-center lg:justify-between p-8 aboutuss" >
  <div className="lg:w-1/2 mb-8"> {/* Add margin-bottom to create space */}

    <h2 className='titreaboutus'>Où que vous alliez, nous sommes là pour vous emballer et vous déplacer</h2>
    <p className="text-lg lg:text-xl text-[#000000] mb-10 textaboutus">
      Déménagement Ultra Pro s'engage à fournir des services de déménagement de qualité, que vous soyez un particulier, une entreprise ou une administration. Découvrez notre histoire, notre équipe dévouée, et notre engagement envers la satisfaction du client.
    </p>
    <div className="p-4">
      <div className="flex space-x-4">
        <button
          className={`hover:bg-[#2966A2] bg-[#26A4DB] px-4 py-2 rounded ${activeTab === 'histoire' ? 'bg-[#26A4DB] text-white' : 'bg-gray-300 text-gray-700'}`}
          onClick={() => handleTabClick('histoire')}
        >
          Notre Histoire
        </button>

        <button
          className={` hover:bg-[#2966A2]  px-4 py-2 rounded ${activeTab === 'equipe' ? 'bg-[#26A4DB] text-white' : 'bg-gray-300 text-gray-700'}`}
          onClick={() => handleTabClick('equipe')}
        >
          Notre Équipe
        </button>
      </div>

      <div className="mt-4">
        {activeTab === 'histoire' && (
          <div>
            <h3 className="text-lg font-semibold mb-2">Notre Histoire</h3>
            <p>
              Découvrez l'évolution de Déménagement Ultra Pro et notre engagement continu envers
              l'excellence dans le domaine du déménagement.
            </p>
          </div>
        )}

        {activeTab === 'equipe' && (
          <div>
            <h3 className="text-lg font-semibold mb-2">Notre Équipe</h3>
            <p>
              Rencontrez les professionnels dévoués derrière Déménagement Ultra Pro, experts dans le
              domaine du déménagement et du transfert industriel.
            </p>
          </div>
        )}
      </div>
    </div>
  </div>
  <div className="lg:w-1/2">
    <img src={aboutUsImage} alt="About Us" className="w-50 h-auto rounded-lg aboutus" />
  </div>
</section>
</div>




{/* SERVICES */}

<section className="flex flex-col p-8 bg-[#2966A2]" id='services'>
<h2 className="text-xl lg:text-2xl font-semibold text-[#ffffff] mb-6 text-center">Nos services</h2>
  <p className="text-lg lg:text-xl text-[#FFFFFF] mb-10 text-center">
    Nous avons les meilleurs services d'emballage et de déménagement.
  </p>
  <div className="flex flex-col lg:flex-row justify-center gap-8">
    <div className="flex-1 max-w-sm bg-[#ffffff] p-6 mb-8 bg-white border border-gray-200 rounded-lg shadow  card">
      <div className="flex items-center flex-col">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<path d="M1 4V25H4.156C4.602 26.719 6.148 28 8 28C9.852 28 11.398 26.719 11.844 25H20.156C20.602 26.719 22.148 28 24 28C25.852 28 27.398 26.719 27.844 25H31V14.594L30.719 14.281L24.719 8.281L24.406 8H19V4H1ZM3 6H17V23H11.844C11.398 21.281 9.852 20 8 20C6.148 20 4.602 21.281 4.156 23H3V6ZM19 10H23.563L29 15.438V23H27.844C27.398 21.281 25.852 20 24 20C22.148 20 20.602 21.281 20.156 23H19V10ZM8 22C9.117 22 10 22.883 10 24C10 25.117 9.117 26 8 26C6.883 26 6 25.117 6 24C6 22.883 6.883 22 8 22ZM24 22C25.117 22 26 22.883 26 24C26 25.117 25.117 26 24 26C22.883 26 22 25.117 22 24C22 22.883 22.883 22 24 22Z" fill="#1C4880"/>
</svg>
        <a href="#">
          <h5 class="mb-2 text-2xl font-semibold tracking-tight text-[#1F2937]">Déménagement RésidenIel</h5>
        </a>
        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400 text-center">Déménagement Ultra Pro assure un déménagement résidentiel sans tracas, s'occupant de tout, de l'emballage au déballage.</p>
      </div>
    </div>

    <div className="flex-1 max-w-sm bg-[#ffffff] p-6 mb-8 bg-white border border-gray-200 rounded-lg shadow  card">
      <div className="flex items-center flex-col">
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
<path d="M23.125 17.745V22.1294C23.125 24.405 21.3056 26.25 19.0606 26.25C16.8156 26.25 14.9962 24.405 14.9962 22.1294L15.0038 8.21437C15.0038 5.74875 13.1844 3.75 10.9394 3.75C8.695 3.75 6.875 5.74875 6.875 8.21437V17.7456" stroke="#1C4880" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M26.875 19.375L23.125 15.625L19.375 19.375M26.875 6.7044C26.875 9.48878 23.125 11.875 23.125 11.875C23.125 11.875 19.375 9.48878 19.375 6.7044C19.375 5.75503 19.77 4.84503 20.4731 4.17315C21.1899 3.49658 22.1393 3.12134 23.125 3.12503C24.1106 3.12147 25.06 3.49669 25.7769 4.17315C26.1219 4.4986 26.3972 4.89081 26.586 5.32596C26.7748 5.7611 26.8731 6.23008 26.875 6.7044ZM10.625 21.7044C10.625 24.4888 6.875 26.875 6.875 26.875C6.875 26.875 3.125 24.4888 3.125 21.7044C3.125 20.755 3.52 19.845 4.22312 19.1732C4.93993 18.4966 5.88933 18.1213 6.875 18.125C7.86065 18.1215 8.81 18.4967 9.52688 19.1732C9.87194 19.4986 10.1472 19.8908 10.336 20.326C10.5248 20.7611 10.6231 21.2301 10.625 21.7044Z" stroke="#1C4880" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M23.125 8.125C23.8154 8.125 24.375 7.56536 24.375 6.875C24.375 6.18464 23.8154 5.625 23.125 5.625C22.4346 5.625 21.875 6.18464 21.875 6.875C21.875 7.56536 22.4346 8.125 23.125 8.125Z" fill="#1C4880"/>
<path d="M6.875 23.125C7.56536 23.125 8.125 22.5654 8.125 21.875C8.125 21.1846 7.56536 20.625 6.875 20.625C6.18464 20.625 5.625 21.1846 5.625 21.875C5.625 22.5654 6.18464 23.125 6.875 23.125Z" fill="#1C4880"/>
</svg>
        <a href="#">
          <h5 class="mb-2 text-2xl font-semibold tracking-tight text-[#1F2937] ">Transfert Industriel:</h5>
        </a>
        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400 text-center">Pour les entreprises cherchant une transiIon fluide, notre équipe qualifiée assure le transfert efficace d'équipements, de machines et de matériaux.</p>
      </div>
    </div>

    <div className="flex-1 max-w-sm bg-[#ffffff] p-6 mb-8 bg-white border border-gray-200 rounded-lg shadow  card">
      <div className="flex items-center flex-col">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
<path d="M27.707 7.70708C27.8892 7.51848 27.99 7.26587 27.9877 7.00368C27.9854 6.74148 27.8802 6.49067 27.6948 6.30526C27.5094 6.11985 27.2586 6.01468 26.9964 6.0124C26.7342 6.01013 26.4816 6.11092 26.293 6.29308L24 8.58608L21.707 6.29308C21.6148 6.19757 21.5044 6.12139 21.3824 6.06898C21.2604 6.01657 21.1292 5.98898 20.9964 5.98783C20.8636 5.98667 20.732 6.01198 20.6091 6.06226C20.4862 6.11254 20.3745 6.18679 20.2806 6.28068C20.1867 6.37458 20.1125 6.48623 20.0622 6.60913C20.0119 6.73202 19.9866 6.8637 19.9878 6.99648C19.9889 7.12926 20.0165 7.26048 20.0689 7.38248C20.1213 7.50449 20.1975 7.61483 20.293 7.70708L22.586 10.0001L20.293 12.2931C20.1109 12.4817 20.0101 12.7343 20.0123 12.9965C20.0146 13.2587 20.1198 13.5095 20.3052 13.6949C20.4906 13.8803 20.7414 13.9855 21.0036 13.9877C21.2658 13.99 21.5184 13.8892 21.707 13.7071L24 11.4141L26.293 13.7071C26.4807 13.8946 26.7351 13.9999 27.0004 13.9998C27.2656 13.9997 27.52 13.8942 27.7075 13.7066C27.895 13.5189 28.0003 13.2645 28.0002 12.9992C28.0001 12.7339 27.8947 12.4796 27.707 12.2921L25.414 10.0001L27.707 7.70708ZM33.949 32.1841C33.9906 32.3087 34.0071 32.4403 33.9978 32.5713C33.9884 32.7023 33.9534 32.8302 33.8946 32.9477C33.8358 33.0652 33.7545 33.1699 33.6552 33.2559C33.5559 33.342 33.4407 33.4076 33.316 33.4491L28.816 34.9491C28.5643 35.0329 28.2897 35.0133 28.0524 34.8945C27.8152 34.7758 27.6348 34.5678 27.551 34.3161C27.4672 34.0644 27.4868 33.7897 27.6055 33.5525C27.7242 33.3152 27.9323 33.1349 28.184 33.0511L32.684 31.5511C32.8086 31.5095 32.9402 31.493 33.0713 31.5023C33.2023 31.5116 33.3302 31.5467 33.4477 31.6055C33.5651 31.6643 33.6699 31.7456 33.7559 31.8449C33.8419 31.9442 33.9076 32.0594 33.949 32.1841Z" fill="#1C4880"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.68402 26.449L10 27.554V36C10 36.2079 10.0648 36.4107 10.1855 36.58C10.3061 36.7494 10.4765 36.877 10.673 36.945L23.665 41.442C23.8703 41.5157 24.0943 41.5196 24.302 41.453L24.316 41.449L24.331 41.444L37.327 36.945C37.5235 36.877 37.6939 36.7494 37.8146 36.58C37.9352 36.4107 38 36.2079 38 36V27.554L41.316 26.449C41.469 26.3981 41.6074 26.311 41.7194 26.1951C41.8315 26.0792 41.9138 25.938 41.9595 25.7834C42.0052 25.6288 42.0128 25.4655 41.9817 25.3073C41.9506 25.1491 41.8818 25.0008 41.781 24.875L37.781 19.875C37.6624 19.7273 37.5043 19.6163 37.325 19.555L24.327 15.055C24.1152 14.9817 23.8849 14.9817 23.673 15.055L10.675 19.555C10.4958 19.6162 10.3376 19.7272 10.219 19.875L6.21902 24.875C6.11826 25.0008 6.04941 25.1491 6.01833 25.3073C5.98726 25.4655 5.99488 25.6288 6.04055 25.7834C6.08622 25.938 6.16858 26.0792 6.28062 26.1951C6.39266 26.311 6.53106 26.3981 6.68402 26.449ZM21.319 30.573L23 28.173V39.096L12 35.288V28.22L20.184 30.948C20.3885 31.0159 20.6094 31.016 20.8139 30.9483C21.0184 30.8805 21.1956 30.7495 21.319 30.573ZM14.057 20.5L24 23.942L33.943 20.5L24 17.058L14.057 20.5ZM26.681 30.573L25 28.174V39.097L36 35.289V28.22L27.816 30.948C27.6116 31.0159 27.3907 31.016 27.1861 30.9483C26.9816 30.8805 26.8045 30.7495 26.681 30.573ZM11.34 21.676L8.67702 25.005L14.188 26.842L20.108 28.815L22.421 25.512L22.286 25.465L11.34 21.675V21.676ZM39.323 25.005L36.66 21.675L25.579 25.512L27.892 28.815L39.323 25.005Z" fill="#1C4880"/>
</svg>
        <a href="#">
          <h5 class="mb-2 text-2xl font-semibold tracking-tight text-[#1F2937]">Garde-Meuble</h5>
        </a>
        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400 text-center">Stockez vos biens en toute sécurité dans nos installaIons de garde-meuble modernes et surveillées.</p>
      </div>
    </div>
  </div>
</section>



{/* WHY US */}<h2 className=" ml-12 text-xl lg:text-2xl font-semibold text-[#2966A2] mb-6 mt-8" id='nous'>Pourquoi nous Choisir</h2>
<section className="flex flex-col items-center lg:flex-row lg:justify-between p-8 mx-4">

      {/* Left Vertical Div */}
      <div className="flex flex-col items-center mb-8 lg:mb-0">
        {/* Icon (Replace with your desired icon) */}
        
        <svg className="w-8 h-8 mb-2" xmlns="http://www.w3.org/2000/svg" width="24" height="28" viewBox="0 0 24 28" fill="none">
<path d="M24 20C24 20.5066 23.72 20.9466 23.2933 21.1733L12.76 27.0933C12.5467 27.2533 12.28 27.3333 12 27.3333C11.72 27.3333 11.4533 27.2533 11.24 27.0933L0.706672 21.1733C0.492911 21.061 0.314021 20.8922 0.189436 20.6853C0.0648507 20.4785 -0.00066556 20.2414 5.09766e-06 20V7.99996C5.09766e-06 7.49329 0.280005 7.05329 0.706672 6.82663L11.24 0.906626C11.4533 0.746626 11.72 0.666626 12 0.666626C12.28 0.666626 12.5467 0.746626 12.76 0.906626L23.2933 6.82663C23.72 7.05329 24 7.49329 24 7.99996V20ZM2.66667 9.99996V18H4.33334V15.3333H5.33334C6.04058 15.3333 6.71886 15.0523 7.21896 14.5522C7.71905 14.0521 8 13.3739 8 12.6666C8 11.9594 7.71905 11.2811 7.21896 10.781C6.71886 10.2809 6.04058 9.99996 5.33334 9.99996H2.66667ZM4.33334 14V11.3333H5C5.35363 11.3333 5.69277 11.4738 5.94281 11.7238C6.19286 11.9739 6.33334 12.313 6.33334 12.6666C6.33334 13.0202 6.19286 13.3594 5.94281 13.6094C5.69277 13.8595 5.35363 14 5 14H4.33334ZM9 9.99996V18H10.6667V15.3333H11.6667L12.5467 18H14.3067L13.2533 14.8133C13.9067 14.3333 14.3333 13.5466 14.3333 12.6666C14.3333 11.9594 14.0524 11.2811 13.5523 10.781C13.0522 10.2809 12.3739 9.99996 11.6667 9.99996H9ZM10.6667 14V11.3333H11.3333C11.687 11.3333 12.0261 11.4738 12.2761 11.7238C12.5262 11.9739 12.6667 12.313 12.6667 12.6666C12.6667 13.0202 12.5262 13.3594 12.2761 13.6094C12.0261 13.8595 11.687 14 11.3333 14H10.6667ZM18.6667 9.99996C16.8267 9.99996 15.3333 11.7866 15.3333 14C15.3333 16.2133 16.8267 18 18.6667 18C20.5067 18 22 16.2133 22 14C22 11.7866 20.5067 9.99996 18.6667 9.99996ZM18.6667 11.6666C19.68 11.6666 20.5067 12.7066 20.5067 14C20.5067 15.2933 19.68 16.3333 18.6667 16.3333C17.6533 16.3333 16.84 15.2933 16.84 14C16.84 12.7066 17.6533 11.6666 18.6667 11.6666Z" fill="#2966A2"/>
</svg>
        {/* Title and Paragraph */}
        <div className="text-center">
          <h3 className="text-lg font-semibold mb-2 titre">
Expertise professionnelle</h3>
          <p className="text-sm text-gray-600 nous"> Notre équipe hautement qualifiée offre une expertise professionnelle inégalée, assurant une gestion experte de votre déménagement de A à Z.</p>
        </div>
        <br/>
        <br/>
        {/* Second Icon, Title, and Paragraph */}
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 26" fill="none">
<path d="M3.21467 2.95997C4.76125 1.41275 6.83573 0.507651 9.02187 0.426289C11.208 0.344928 13.344 1.09332 15.0013 2.5213C16.657 1.09553 18.7901 0.347743 20.9736 0.427651C23.1571 0.507559 25.2299 1.40926 26.7769 2.95224C28.3239 4.49522 29.231 6.5656 29.3167 8.74889C29.4023 10.9322 28.6601 13.0672 27.2387 14.7266L16.8853 25.1146C16.4097 25.5905 15.7717 25.8689 15.0994 25.8941C14.427 25.9194 13.77 25.6895 13.26 25.2506L13.1133 25.1146L2.76133 14.728C1.34027 13.0699 0.597488 10.9365 0.681537 8.75446C0.765587 6.57238 1.67027 4.50379 3.21467 2.95997ZM5.1 4.84397C4.00927 5.93468 3.3805 7.40357 3.34426 8.94565C3.30801 10.4877 3.86706 11.9845 4.90533 13.1253L5.1 13.3293L15 23.2293L22.0707 16.1573L17.3573 11.444L15.944 12.8573C15.5726 13.2289 15.1317 13.5236 14.6464 13.7248C14.1611 13.9259 13.6409 14.0295 13.1156 14.0296C12.0547 14.0299 11.0371 13.6087 10.2867 12.8586C9.53628 12.1086 9.11458 11.0912 9.11433 10.0302C9.11408 8.96929 9.5353 7.95169 10.2853 7.2013L13.088 4.39864C11.9728 3.50829 10.5768 3.04448 9.1505 3.09041C7.7242 3.13635 6.36095 3.68904 5.30533 4.6493L5.1 4.84397ZM16.4147 8.61597C16.6647 8.36601 17.0038 8.22559 17.3573 8.22559C17.7109 8.22559 18.05 8.36601 18.3 8.61597L23.956 14.272L24.9 13.3293C26.0086 12.2215 26.6398 10.7239 26.6584 9.15672C26.6771 7.58958 26.0819 6.07732 25 4.94337C23.9182 3.80942 22.4356 3.1438 20.8693 3.08884C19.303 3.03388 17.7774 3.59396 16.6187 4.6493L16.4147 4.84397L12.172 9.08664C11.9409 9.31759 11.8026 9.62536 11.7835 9.95152C11.7643 10.2777 11.8655 10.5995 12.068 10.856L12.172 10.9733C12.4031 11.2042 12.711 11.3422 13.0371 11.3612C13.3633 11.3801 13.6851 11.2786 13.9413 11.076L14.0573 10.9733L16.4147 8.61597Z" fill="#2966A2"/>
</svg>
        {/* Title and Paragraph */}
        <div className="text-center">
          <h3 className="text-lg font-semibold mb-2 titre">
          Service personnalisé</h3>
          <p className="text-sm text-gray-600 nous"> 
          Chez Déménagement Ultra Pro, nous comprenons que chaque client est unique. C'est pourquoi nous offrons un service personnalisé, adapté à vos besoins spécifiques pour un déménagement sur mesure.</p>
    
        </div>
      </div>

      {/* Middle Image */}
      <img
        src={whyuspic}
        alt="Your Image"
        className="w-80 h-auto object-cover mb-8 lg:mb-0"
      />

      {/* Right Vertical Div */}
      <div className="flex flex-col items-center">
        {/* Icon (Replace with your desired icon) */}
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="22" viewBox="0 0 32 22" fill="none">
<path d="M3.99998 13L2.99998 11H9.99998L9.19998 9.00004H2.66665L1.66665 7.00004H12.0666L11.2666 5.00004H1.47998L0.333313 3.00004H5.33331C5.33331 2.2928 5.61426 1.61452 6.11436 1.11442C6.61446 0.614325 7.29274 0.333374 7.99998 0.333374H24V5.66671H28L32 11V17.6667H29.3333C29.3333 18.7276 28.9119 19.745 28.1617 20.4951C27.4116 21.2453 26.3942 21.6667 25.3333 21.6667C24.2724 21.6667 23.255 21.2453 22.5049 20.4951C21.7547 19.745 21.3333 18.7276 21.3333 17.6667H16C16 18.7276 15.5786 19.745 14.8284 20.4951C14.0783 21.2453 13.0608 21.6667 12 21.6667C10.9391 21.6667 9.9217 21.2453 9.17155 20.4951C8.42141 19.745 7.99998 18.7276 7.99998 17.6667H5.33331V13H3.99998ZM25.3333 19.6667C25.8637 19.6667 26.3725 19.456 26.7475 19.0809C27.1226 18.7058 27.3333 18.1971 27.3333 17.6667C27.3333 17.1363 27.1226 16.6276 26.7475 16.2525C26.3725 15.8774 25.8637 15.6667 25.3333 15.6667C24.8029 15.6667 24.2942 15.8774 23.9191 16.2525C23.544 16.6276 23.3333 17.1363 23.3333 17.6667C23.3333 18.1971 23.544 18.7058 23.9191 19.0809C24.2942 19.456 24.8029 19.6667 25.3333 19.6667ZM27.3333 7.66671H24V11H29.9466L27.3333 7.66671ZM12 19.6667C12.5304 19.6667 13.0391 19.456 13.4142 19.0809C13.7893 18.7058 14 18.1971 14 17.6667C14 17.1363 13.7893 16.6276 13.4142 16.2525C13.0391 15.8774 12.5304 15.6667 12 15.6667C11.4695 15.6667 10.9608 15.8774 10.5858 16.2525C10.2107 16.6276 9.99998 17.1363 9.99998 17.6667C9.99998 18.1971 10.2107 18.7058 10.5858 19.0809C10.9608 19.456 11.4695 19.6667 12 19.6667Z" fill="#2966A2"/>
</svg>
        {/* Title and Paragraph */}
        <div className="text-center">
          <h3 className="text-lg font-semibold mb-2 titre">
          Livraison rapide</h3>
          <p className="text-sm text-gray-600 nous"> Avec notre service de livraison rapide, votre déménagement est effectué de manière efficace et ponctuelle, garantissant une transition en douceur vers votre nouvelle destination.</p>
    
        </div>
        <br/>
        <br/>
        {/* Second Icon, Title, and Paragraph */}
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="25" viewBox="0 0 28 25" fill="none">
<path d="M0.666687 24.3334V21.6667C0.666687 21.6667 7.33335 19 14 19C20.6667 19 27.3334 21.6667 27.3334 21.6667V24.3334H0.666687ZM13.0667 7.13336C11.4667 1.93336 3.33335 3.13336 3.33335 3.13336C3.33335 3.13336 3.60002 13.5334 11.2 11.9334C10.6667 8.0667 8.66669 7.00003 8.66669 7.00003C12.4 7.00003 12.6667 11.5334 12.6667 11.5334V17.6667H15.3334V12.0667C15.3334 12.0667 15.3334 6.8667 19.3334 5.53336C19.3334 5.53336 16.6667 9.53336 16.6667 12.2C26 13.1334 26 0.333364 26 0.333364C26 0.333364 14.1334 -0.99997 13.0667 7.13336Z" fill="#2966A2"/>
</svg>
        {/* Title and Paragraph */}
        <div className="text-center">
          <h3 className="text-lg font-semibold mb-2 titre">
          Engagement environnemental</h3>
          <p className="text-sm text-gray-600 nous"> Nous sommes fiers de notre engagement envers l'environnement. En adoptant des pratiques respectueuses de la nature, nous minimisons notre impact écologique tout en assurant un déménagement efficace et écoresponsable.</p>
    
        </div>
      </div>
    </section>
    
    


    

      
    <section className="flex flex-col bg-[#2966A2]" id="contact">
      <div className="flex flex-wrap">
        <div className="mb-10 w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 md:px-3 lg:px-6 text-[#ffffff]">
          <br />
          <br />
          <h2 className="mb-6 text-3xl font-bold mt-15 form">Contact us</h2>
          <h4 className="form mb-8 text-[#ffffff]">
            Besoin d'un devis personnalisé? Contactez-nous dès aujourd'hui.
          </h4>
          <p className="mb-2 flex form">
            <FaMapMarkerAlt className="mr-2" /> Casablanca, Morocco
          </p>
          <p className="mb-2 flex form">
            <FaPhone className="mr-2" />   
             06 61 87 12 66/ 06 17 81 79 95 
          </p>
          <p className="mb-6 flex form">
            <FaEnvelope className="mr-2" /> demenagementultrapro@gmail.com
          </p>
          <p className="mb-2 flex form">
            <a href="https://instagram.com/demenagement_ultra_pro?igshid=OGQ5ZDc2ODk2ZA==" className="flex items-center">
              <FaInstagram className="mr-2" />
              Instagram
            </a>
          </p>
          <p className="mb-2 flex form">
            <a href="https://web.facebook.com/demenagementultrapro" className="flex items-center">
              <FaFacebook className="mr-2" />
              Facebook
            </a>
          </p>
        </div>

        <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 md:px-3 lg:px-6">
          <br />
          <br />
          <iframe
            width="100%"
            height="400"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            title="Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27606.634117869065!2d-7.608317478088383!3d33.59569324376948!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7461b3efb67e9%3A0x9ad7d388c356dd1b!2sCasablanca%2C%20Morocco!5e0!3m2!1sen!2sus!4v1637040984811!5m2!1sen!2sus"
            allowFullScreen
          ></iframe>
          <br />
          <br />
        </div>
      </div>
    </section>


  

 

  <footer className="bg-[#1F2937] mt-2 shadow ">
    <div className="w-full p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
            <a  className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                <img src={logoImage} class="h-12" alt="Flowbite Logo" />
            </a>
            <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-[#ffffff] sm:mb-0 gap-2">
                <li>
                    <a href="#home" class="hover:underline me-4 md:me-6">Page d'Accueil</a>
                </li>
                <li>
                    <a href="#about" class="hover:underline me-4 md:me-6">A propos de nous</a>
                </li>
                <li>
                    <a href="#services" class="hover:underline me-4 md:me-6">Nos services</a>
                </li>
                <li>
                    <a href="#nous" class="hover:underline">Pourquoi nous choisir  </a>
                </li>   
                <li>
                    <a href="#contact" class="hover:underline">Contact</a>
                </li>
            </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto  lg:my-8" />
        <span className="block text-sm sm:text-center text-[#FFFFFF]">© DEMENAGEMENT ULTRA PRO <a href="https://startjobs.ma" className="hover:underline"> All Rights Reserved. <br/> Designed by startsjob</a>.</span> 
    </div>
</footer>











    
    </div>
  );
}

export default App;
